@import '_var.scss';


.first-block {
    display: flex;
    align-items: flex-start;
    position: relative;
    min-height: 600px;
    background-size: cover;
    overflow: hidden;

    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 110px;

        .left-block {
            flex: 1;
        }
        .right-block {
            flex: 1;
            max-width: 510px;
            padding: 0 0 0 50px;

            .form {
                max-width: 510px;
                padding: 70px 60px;
                background: $white;
            }
        }
    }

    .main-title {
        color: #fff;
    }

    .back-link {
        position: relative;
        display: inline-block;
        margin: 80px 0 0 0;
        padding: 0 0 0 24px;
        color: $themeColor;
        font-size: 16px;
        font-weight: bold;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 6px);
            left: 0;
            width: 2px;
            height: 14px;
            background: $themeColor;
            transform: rotate(45deg) translateY(-50%);
        }
        &:after {
            top: calc(50% + 3px);
            left: 10px;
            transform: rotate(-45deg) translateY(-50%);
        }
    }
}


/*** Main page ***/

.main-page {
    .header {
        position: absolute;
        top: 0;
        left: 0;

        .wrap {
            border: 0;
        }

        .menu {
            &-item {
                color: $white;

                &.active {
                    color: $themeColor;
                }
            }
        }
        .phone .text {
            color: $white;
        }
    }

    .first-block {
        align-items: center;
        min-height: 100vh;

        .wrap {
            padding-top: 220px;
            padding-bottom: 120px;
        }
    }
}


/*** Catalog ***/

.catalog-block {
    display: flex;
    flex-wrap: wrap;
    margin: 70px 0 0 -35px;

    &--item {
        flex: 1;
        max-width: 25%;
        margin: 70px 0 0 35px;
        font-size: 18px;
        line-height: 34px;
    }

    .image {
        position: relative;
        display: block;
        margin: 0 0 50px;
        padding: 0 0 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: cover;
        }
    }
    .info {
        .title {
            display: inline-block;
            margin: 0 0 30px;
            padding: 0 90px 30px 0;
            font-family: $roboto;
            font-weight: bold;
            font-size: 36px;
            line-height: 46px;
            color: $blue;
            border-bottom: 2px solid $themeColor;
        }
        span {
            display: block;
        }
    }
}


/*** Info block ***/

.info-block {
    display: flex;
    align-items: center;
    margin: 170px 0 0;

    .left-block {
        flex: 1;
        padding: 0 40px 0 0;
    }
    .right-block {
        flex: 1;
        max-width: 680px;
    }

    .title {
        margin: 0 0 50px;
    }

    .btn {
        margin: 50px 0 0;
    }
}


/*** Review block ***/

.review-block {
    position: relative;
    margin: 160px 0 0;
    padding: 0 0 90px;

    .slider {
        position: absolute;
        left: 50%;
        bottom: 0;
        max-width: 620px;
        margin: 0;
        padding: 60px 90px 120px;
        background: $white;
        transform: translateX(-50%);

        &--item {
            height: auto;
        }

        .title {
            margin: 0 0 60px;
            text-align: center;
        }

        p {
            font-size: 24px;
            line-height: 46px;
            font-style: italic;
        }

        .man {
            margin: 60px 0 0;
            font-size: 18px;
            line-height: 34px;

            .name {
                margin: 0 0 10px;
                font-weight: bold;
                color: $themeColor;
            }
        }

        .slick-dots {
            bottom: 50px;
            width: auto;
            font-size: 0;

            li {
                width: 17px;
                height: 17px;
                margin: 0 0 0 10px;

                &:first-child {
                    margin: 0;
                }
                &:before {
                    display: none;
                }

                button {
                    width: 17px;
                    height: 17px;
                    margin: 0;
                    padding: 0;
                    border: 1px solid $themeColor;
                    border-radius: 50%;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background: $themeColor;
                    }
                }
            }
        }
    }
}


/*** Brand block ***/

.brand-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 160px -20px -20px;

    &--item {
        padding: 20px;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }
}


/*** Columns ***/

.columns-block {
    display: flex;

    .h3 {
        margin-top: 100px;
        font-size: 36px;
        line-height: 128.91%;
    }

    .column {
        flex: 1;
        max-width: 450px;
        background: $lightDark;
        padding: 110px 60px 0 0;

        &:first-child {
            max-width: 68%;
            margin: -260px 60px 0 0;
            padding: 70px;
        }
    }

    h2 {
        margin: 80px 0 50px;
    }
    .title-content {
        margin: 0 0 80px;
    }
    img {
        margin: 80px 0 0;
    }

    .brands {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px -20px -40px;

        &--item {
            flex: 25%;
            padding: 40px 20px;
            text-align: center;
        }

        img {
            margin: 0;
        }
    }

    .form,
    .wrap {
        position: sticky;
        top: 20px;
    }

    .wrap {
        .label {
            margin: 0 0 30px;
            line-height: 28px;
            text-transform: uppercase;
        }
    }

    .catalog-block {
        &--item {
            flex: calc(50% - 35px);
            max-width: calc(50% - 35px);
        }
    }

    .testimonials-block {
        margin: 0;
        padding: 0;
        background: none;
    }
}


/*** About block ***/

.about.block {
    position: relative;
    margin: 200px 0 0;

    .image {
        width: 100%;
        height: 768px;
        padding: 0 0 128px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text {
        position: absolute;
        left: 40px;
        bottom: 0;
        max-width: 900px;
        padding: 70px;
        background: $white;

        h2 {
            margin: 0 0 60px;
        }

        a {
            text-decoration: underline;
        }
    }
}


/*** Testimonials ***/

.testimonials-wrap {
    position: relative;
    margin: 90px 0 0;

    .image {
        height: 550px;
        margin-bottom: -30%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.testimonials-block {
    max-width: 900px;
    padding: 70px;
    background: $white;
    font-style: italic;

    h3 {
        margin: 0 0 50px;
    }

    .man {
        margin: 60px 0 0;
        font-style: normal;

        .name {
            color: $themeColor;
            font-weight: bold;
        }
        .job {
            margin: 10px 0 0;
        }
    }
}


/*** Blog ***/

.blog-list {
    .item {
        display: flex;
        margin: 60px 0 0;

        &:first-child {
            margin: 0;
        }
    }

    .image {
        flex: 1;
        max-width: 280px;
        max-height: 295px;

        img {
            width: 100%;
            margin: 0;
            object-fit: cover;
        }
    }

    .info {
        flex: 1;
        padding: 0 0 0 50px;

        .title {
            font-family: $roboto;
            color: $blue;
            font-weight: bold;
            font-size: 36px;
            line-height: 46px;
        }
        .category {
            margin: 10px 0 0;
            font-size: 12px;
            line-height: 20px;
            color: $themeColor;
        }
        p {
            margin: 10px 0 0;
        }

        .button-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 30px 0 0;
        }
        .link {
            font-family: $roboto;
            font-weight: bold;
            color: $themeColor;
            text-transform: uppercase;
        }
        .time {
            font-size: 12px;
            line-height: 20px;
            color: $blue;
        }
    }
}

.blog-one {
    margin: 0 0 80px;
    padding: 0 0 80px;
    border-bottom: 1px solid $grayDark;

    .title {
        margin: 0 0 25px;
        font-size: 36px;
        line-height: 46px;
    }
    .category {
        color: $themeColor;
        font-size: 12px;
        line-height: 20px;
    }
    img {
        margin: 50px 0 40px;
    }
}

.pagination {
    display: flex;
    align-items: center;
    margin: 200px 0 0;

    .label {
        margin: 0 15px 0 0;
        font-weight: 900;
        color: $blue;
        text-transform: uppercase;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin: 0 5px;
        color: $themeColor;
        border-radius: 50%;

        &.active {
            background: $themeColor;
            color: $white;
        }
    }
}

.search-block {
    position: relative;
    margin: 0 0 30px;

    .input {
        padding: 0 40px 0 20px;
    }
    button {
        position: absolute;
        top: 50%;
        right: 10px;
        font-size: 18px;
        border: 0;
        background: none;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.category-list {
    .item {
        display: block;
        margin: 30px 0 0;
        padding: 0 0 0 20px;
        line-height: 19px;
        color: $themeColor;

        &:first-child {
            margin: 0;
        }

        .count {
            margin: 0 0 0 5px;
            font-size: 12px;
            line-height: 14px;
        }

        &.active {
            color: $blue;
        }
    }
}


/*** Contacts ***/

.contact-block {
    display: flex;
    flex-wrap: wrap;
    margin: -50px 0 0;

    .item {
        flex: 50%;
        max-width: 50%;
        margin: 50px 0 0;
        padding: 0 80px 0 0;
    }
    .title {
        margin: 0 0 40px;
    }

    .info-wrap {
        display: flex;
        align-items: center;
        padding: 0 0 20px;

        &:last-child {
            padding: 0;
        }

        .text {
            flex: 1;
        }
        .label {
            flex: 1;
            color: $blue;

            &.small {
                font-size: 14px;
                padding: 0 0 0 40px;
            }
        }
    }

    .links {
        a {
            text-decoration: underline;
        }
    }
    
    .working-hours {
        max-width: 370px;
        padding: 30px 20px 40px;
        background: $blue;
        color: $white;
        font-size: 18px;
        line-height: 25px;
        text-align: center;

        .title {
            margin: 0 0 35px;
            font-family: $roboto;
            font-weight: bold;
            font-size: 24px;
            line-height: 128.91%;
        }

        &-item {
            margin: 20px 0 0;
        }
    }
}


/***/

.map-wrap {
    height: 700px;
    margin: 120px 0 0;
}