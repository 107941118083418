/*** Fonts ***/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i");
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icons/icomoon.eot?lcl2uk");
  src: url("../fonts/icons/icomoon.eot?lcl2uk#iefix") format("embedded-opentype"), url("../fonts/icons/icomoon.ttf?lcl2uk") format("truetype"), url("../fonts/icons/icomoon.woff?lcl2uk") format("woff"), url("../fonts/icons/icomoon.svg?lcl2uk#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-facebook:before {
  content: "\e900";
  color: #4ca3da; }

.icon-key-fill .path1:before {
  content: "\e901";
  color: white; }

.icon-key-fill .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: #4ca3da; }

.icon-location-fill .path1:before {
  content: "\e903";
  color: white; }

.icon-location-fill .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: #4ca3da; }

.icon-mail-fill .path1:before {
  content: "\e905";
  color: white; }

.icon-mail-fill .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: #4ca3da; }

.icon-phone:before {
  content: "\e907";
  color: #4ca3da; }

.icon-phone-fill .path1:before {
  content: "\e908";
  color: white; }

.icon-phone-fill .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: #4ca3da; }

.icon-search:before {
  content: "\e90a";
  color: #4ca3da; }

.icon-user-fill .path1:before {
  content: "\e90b";
  color: white; }

.icon-user-fill .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: #4ca3da; }

/*** Colors ***/
.first-block {
  display: flex;
  align-items: flex-start;
  position: relative;
  min-height: 600px;
  background-size: cover;
  overflow: hidden; }
  .first-block .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 110px; }
    .first-block .wrap .left-block {
      flex: 1; }
    .first-block .wrap .right-block {
      flex: 1;
      max-width: 510px;
      padding: 0 0 0 50px; }
      .first-block .wrap .right-block .form {
        max-width: 510px;
        padding: 70px 60px;
        background: #ffffff; }
  .first-block .main-title {
    color: #fff; }
  .first-block .back-link {
    position: relative;
    display: inline-block;
    margin: 80px 0 0 0;
    padding: 0 0 0 24px;
    color: #4CA3DA;
    font-size: 16px;
    font-weight: bold; }
    .first-block .back-link:before, .first-block .back-link:after {
      content: '';
      position: absolute;
      top: calc(50% - 6px);
      left: 0;
      width: 2px;
      height: 14px;
      background: #4CA3DA;
      transform: rotate(45deg) translateY(-50%); }
    .first-block .back-link:after {
      top: calc(50% + 3px);
      left: 10px;
      transform: rotate(-45deg) translateY(-50%); }

/*** Main page ***/
.main-page .header {
  position: absolute;
  top: 0;
  left: 0; }
  .main-page .header .wrap {
    border: 0; }
  .main-page .header .menu-item {
    color: #ffffff; }
    .main-page .header .menu-item.active {
      color: #4CA3DA; }
  .main-page .header .phone .text {
    color: #ffffff; }

.main-page .first-block {
  align-items: center;
  min-height: 100vh; }
  .main-page .first-block .wrap {
    padding-top: 220px;
    padding-bottom: 120px; }

/*** Catalog ***/
.catalog-block {
  display: flex;
  flex-wrap: wrap;
  margin: 70px 0 0 -35px; }
  .catalog-block--item {
    flex: 1;
    max-width: 25%;
    margin: 70px 0 0 35px;
    font-size: 18px;
    line-height: 34px; }
  .catalog-block .image {
    position: relative;
    display: block;
    margin: 0 0 50px;
    padding: 0 0 100%; }
    .catalog-block .image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      object-fit: cover; }
  .catalog-block .info .title {
    display: inline-block;
    margin: 0 0 30px;
    padding: 0 90px 30px 0;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    color: #0B476B;
    border-bottom: 2px solid #4CA3DA; }
  .catalog-block .info span {
    display: block; }

/*** Info block ***/
.info-block {
  display: flex;
  align-items: center;
  margin: 170px 0 0; }
  .info-block .left-block {
    flex: 1;
    padding: 0 40px 0 0; }
  .info-block .right-block {
    flex: 1;
    max-width: 680px; }
  .info-block .title {
    margin: 0 0 50px; }
  .info-block .btn {
    margin: 50px 0 0; }

/*** Review block ***/
.review-block {
  position: relative;
  margin: 160px 0 0;
  padding: 0 0 90px; }
  .review-block .slider {
    position: absolute;
    left: 50%;
    bottom: 0;
    max-width: 620px;
    margin: 0;
    padding: 60px 90px 120px;
    background: #ffffff;
    transform: translateX(-50%); }
    .review-block .slider--item {
      height: auto; }
    .review-block .slider .title {
      margin: 0 0 60px;
      text-align: center; }
    .review-block .slider p {
      font-size: 24px;
      line-height: 46px;
      font-style: italic; }
    .review-block .slider .man {
      margin: 60px 0 0;
      font-size: 18px;
      line-height: 34px; }
      .review-block .slider .man .name {
        margin: 0 0 10px;
        font-weight: bold;
        color: #4CA3DA; }
    .review-block .slider .slick-dots {
      bottom: 50px;
      width: auto;
      font-size: 0; }
      .review-block .slider .slick-dots li {
        width: 17px;
        height: 17px;
        margin: 0 0 0 10px; }
        .review-block .slider .slick-dots li:first-child {
          margin: 0; }
        .review-block .slider .slick-dots li:before {
          display: none; }
        .review-block .slider .slick-dots li button {
          width: 17px;
          height: 17px;
          margin: 0;
          padding: 0;
          border: 1px solid #4CA3DA;
          border-radius: 50%; }
          .review-block .slider .slick-dots li button:before {
            display: none; }
        .review-block .slider .slick-dots li.slick-active button {
          background: #4CA3DA; }

/*** Brand block ***/
.brand-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 160px -20px -20px; }
  .brand-block--item {
    padding: 20px; }
  .brand-block .slick-track {
    display: flex;
    align-items: center; }

/*** Columns ***/
.columns-block {
  display: flex; }
  .columns-block .h3 {
    margin-top: 100px;
    font-size: 36px;
    line-height: 128.91%; }
  .columns-block .column {
    flex: 1;
    max-width: 450px;
    background: #F7F8F8;
    padding: 110px 60px 0 0; }
    .columns-block .column:first-child {
      max-width: 68%;
      margin: -260px 60px 0 0;
      padding: 70px; }
  .columns-block h2 {
    margin: 80px 0 50px; }
  .columns-block .title-content {
    margin: 0 0 80px; }
  .columns-block img {
    margin: 80px 0 0; }
  .columns-block .brands {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px -20px -40px; }
    .columns-block .brands--item {
      flex: 25%;
      padding: 40px 20px;
      text-align: center; }
    .columns-block .brands img {
      margin: 0; }
  .columns-block .form,
  .columns-block .wrap {
    position: sticky;
    top: 20px; }
  .columns-block .wrap .label {
    margin: 0 0 30px;
    line-height: 28px;
    text-transform: uppercase; }
  .columns-block .catalog-block--item {
    flex: calc(50% - 35px);
    max-width: calc(50% - 35px); }
  .columns-block .testimonials-block {
    margin: 0;
    padding: 0;
    background: none; }

/*** About block ***/
.about.block {
  position: relative;
  margin: 200px 0 0; }
  .about.block .image {
    width: 100%;
    height: 768px;
    padding: 0 0 128px; }
    .about.block .image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .about.block .text {
    position: absolute;
    left: 40px;
    bottom: 0;
    max-width: 900px;
    padding: 70px;
    background: #ffffff; }
    .about.block .text h2 {
      margin: 0 0 60px; }
    .about.block .text a {
      text-decoration: underline; }

/*** Testimonials ***/
.testimonials-wrap {
  position: relative;
  margin: 90px 0 0; }
  .testimonials-wrap .image {
    height: 550px;
    margin-bottom: -30%; }
    .testimonials-wrap .image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }

.testimonials-block {
  max-width: 900px;
  padding: 70px;
  background: #ffffff;
  font-style: italic; }
  .testimonials-block h3 {
    margin: 0 0 50px; }
  .testimonials-block .man {
    margin: 60px 0 0;
    font-style: normal; }
    .testimonials-block .man .name {
      color: #4CA3DA;
      font-weight: bold; }
    .testimonials-block .man .job {
      margin: 10px 0 0; }

/*** Blog ***/
.blog-list .item {
  display: flex;
  margin: 60px 0 0; }
  .blog-list .item:first-child {
    margin: 0; }

.blog-list .image {
  flex: 1;
  max-width: 280px;
  max-height: 295px; }
  .blog-list .image img {
    width: 100%;
    margin: 0;
    object-fit: cover; }

.blog-list .info {
  flex: 1;
  padding: 0 0 0 50px; }
  .blog-list .info .title {
    font-family: "Roboto Condensed", sans-serif;
    color: #0B476B;
    font-weight: bold;
    font-size: 36px;
    line-height: 46px; }
  .blog-list .info .category {
    margin: 10px 0 0;
    font-size: 12px;
    line-height: 20px;
    color: #4CA3DA; }
  .blog-list .info p {
    margin: 10px 0 0; }
  .blog-list .info .button-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 0; }
  .blog-list .info .link {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #4CA3DA;
    text-transform: uppercase; }
  .blog-list .info .time {
    font-size: 12px;
    line-height: 20px;
    color: #0B476B; }

.blog-one {
  margin: 0 0 80px;
  padding: 0 0 80px;
  border-bottom: 1px solid #C4C4C4; }
  .blog-one .title {
    margin: 0 0 25px;
    font-size: 36px;
    line-height: 46px; }
  .blog-one .category {
    color: #4CA3DA;
    font-size: 12px;
    line-height: 20px; }
  .blog-one img {
    margin: 50px 0 40px; }

.pagination {
  display: flex;
  align-items: center;
  margin: 200px 0 0; }
  .pagination .label {
    margin: 0 15px 0 0;
    font-weight: 900;
    color: #0B476B;
    text-transform: uppercase; }
  .pagination .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 5px;
    color: #4CA3DA;
    border-radius: 50%; }
    .pagination .item.active {
      background: #4CA3DA;
      color: #ffffff; }

.search-block {
  position: relative;
  margin: 0 0 30px; }
  .search-block .input {
    padding: 0 40px 0 20px; }
  .search-block button {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 18px;
    border: 0;
    background: none;
    transform: translateY(-50%);
    cursor: pointer; }

.category-list .item {
  display: block;
  margin: 30px 0 0;
  padding: 0 0 0 20px;
  line-height: 19px;
  color: #4CA3DA; }
  .category-list .item:first-child {
    margin: 0; }
  .category-list .item .count {
    margin: 0 0 0 5px;
    font-size: 12px;
    line-height: 14px; }
  .category-list .item.active {
    color: #0B476B; }

/*** Contacts ***/
.contact-block {
  display: flex;
  flex-wrap: wrap;
  margin: -50px 0 0; }
  .contact-block .item {
    flex: 50%;
    max-width: 50%;
    margin: 50px 0 0;
    padding: 0 80px 0 0; }
  .contact-block .title {
    margin: 0 0 40px; }
  .contact-block .info-wrap {
    display: flex;
    align-items: center;
    padding: 0 0 20px; }
    .contact-block .info-wrap:last-child {
      padding: 0; }
    .contact-block .info-wrap .text {
      flex: 1; }
    .contact-block .info-wrap .label {
      flex: 1;
      color: #0B476B; }
      .contact-block .info-wrap .label.small {
        font-size: 14px;
        padding: 0 0 0 40px; }
  .contact-block .links a {
    text-decoration: underline; }
  .contact-block .working-hours {
    max-width: 370px;
    padding: 30px 20px 40px;
    background: #0B476B;
    color: #ffffff;
    font-size: 18px;
    line-height: 25px;
    text-align: center; }
    .contact-block .working-hours .title {
      margin: 0 0 35px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 128.91%; }
    .contact-block .working-hours-item {
      margin: 20px 0 0; }

/***/
.map-wrap {
  height: 700px;
  margin: 120px 0 0; }
