/*** Fonts ***/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icons/icomoon.eot?lcl2uk');
    src:  url('../fonts/icons/icomoon.eot?lcl2uk#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon.ttf?lcl2uk') format('truetype'),
    url('../fonts/icons/icomoon.woff?lcl2uk') format('woff'),
    url('../fonts/icons/icomoon.svg?lcl2uk#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
    content: "\e900";
    color: #4ca3da;
}
.icon-key-fill .path1:before {
    content: "\e901";
    color: rgb(255, 255, 255);
}
.icon-key-fill .path2:before {
    content: "\e902";
    margin-left: -1em;
    color: rgb(76, 163, 218);
}
.icon-location-fill .path1:before {
    content: "\e903";
    color: rgb(255, 255, 255);
}
.icon-location-fill .path2:before {
    content: "\e904";
    margin-left: -1em;
    color: rgb(76, 163, 218);
}
.icon-mail-fill .path1:before {
    content: "\e905";
    color: rgb(255, 255, 255);
}
.icon-mail-fill .path2:before {
    content: "\e906";
    margin-left: -1em;
    color: rgb(76, 163, 218);
}
.icon-phone:before {
    content: "\e907";
    color: #4ca3da;
}
.icon-phone-fill .path1:before {
    content: "\e908";
    color: rgb(255, 255, 255);
}
.icon-phone-fill .path2:before {
    content: "\e909";
    margin-left: -1em;
    color: rgb(76, 163, 218);
}
.icon-search:before {
    content: "\e90a";
    color: #4ca3da;
}
.icon-user-fill .path1:before {
    content: "\e90b";
    color: rgb(255, 255, 255);
}
.icon-user-fill .path2:before {
    content: "\e90c";
    margin-left: -1em;
    color: rgb(76, 163, 218);
}


$openSans: 'Open Sans', sans-serif;
$roboto: 'Roboto Condensed', sans-serif;


/*** Colors ***/

$white: #ffffff;
$black: #000;
$red: #e2767d;

$blue: #0B476B;
$blueDark: #58656C;

$lightDark: #F7F8F8;
$gray: #F2F2F2;
$grayDark: #C4C4C4;

$themeColor: #4CA3DA;
$borderColor: #EBEBEB;


$linkColor: #4CA3DA;
$textColor: #58656C;